import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logoZh from '#static/img/new-register-logo.png';
import logoEn from '#static/img/logo-en.png';
// import logoZh from '#static/img/new_logo.png';
// import logoEn from '#static/img/new_logo_en.png';
import logoZhVertical from '#static/img/new_logo_vertical.png';
import logoEnVertical from '#static/img/new_logo_en_vertical.png';

import logoMobile from '#static/img/logo_mobile.png';
import { get } from '#utils/globalVariable';
import { connect } from 'react-redux';


// let imgHeight = navigator.language === 'zh-CN' ? '38px' : '28px';
const Img = styled.img`
  height: ${props => props.imgHeight};
`;
// let logoWeb = navigator.language === 'zh-CN' ? logoZh : logoEn;

const getImgHeight = (isVertical) => {
  if (isVertical) {
    return '32px';
  }
  // return navigator.language.indexOf('zh') !== -1 ? '38px' : '28px';
  return '38px';
};

const getLogoWeb = (isVertical) => {
  // let logo;
  // if (navigator.language.indexOf('zh') !== -1) {
  //   logo = isVertical ? logoZhVertical : logoZh;
  // } else {
  //   logo = isVertical ? logoEnVertical : logoEn;
  // }
  // return logo;
  return navigator.language.indexOf('zh') !== -1 ? logoZhVertical : logoEnVertical;
};
const Logo = ({ imgSrc, isVertical }) => {
  const defaultLogoImg = get('env') === 'WEB' ? getLogoWeb(isVertical) : logoMobile;
  return (
    <Img src={imgSrc || defaultLogoImg} imgHeight={getImgHeight(isVertical)} />
  );
};

Logo.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  isVertical: PropTypes.bool,
};

Logo.defaultProps = {
  isVertical: false,
};

function mapStateToProps(state) {
  return {
    imgSrc: state.brandInfo.logo.picture_url,
  };
}

export default connect(mapStateToProps)(Logo);
