/* eslint-disable */
import React from "react";
import styled from "styled-components";
import StringUtil from "../utils/StringUtil";

const Input = styled.input.attrs(props => ({
  "data-ta-key": props["data-ta-key"]
}))`
  color: #191f25;
  font-size: 14px;
  text-indent: 0;
  line-height: 18px;
  height: 46px;
  border: none;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;

  &::-webkit-caps-lock-indicator {
    align-self: unset;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  &[type="number"] {
      -moz-appearance: textfield; /* Firefox */
      appearance: textfield; /* 其他现代浏览器 */
  }
`;

const Line = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid #e6e6e6;
  transition: border-bottom 0.3s ease-in-out;
  border-radius: 0;
  /* padding: 3px; */
  margin-top: 30px;
  
  &:first-child{
    margin-top: 40px;
    margin-top: ${props => (props.isLoginPage ? '30px' : '40px')};
  }

  .fake-placeholder{
    width: 100%;
    position: absolute;
    cursor: text;
    font-size: ${props => (props.hasInput ? "12px" : "14px")};
    line-height: 14px;
    color: #aaaaaa;
    background-color: #ffffff;
    bottom: ${props => (props.hasInput ? "42px" : "15px")};
    transition: .2s ease;
  }

  &:focus-within {
    border-bottom: 1px solid #0564f5;

    .fake-placeholder{
      font-size: 12px;
      bottom: 42px;
    }
  }
`;
const ErrorMsg = styled.div`
word-break: break-word;
font-size: 12px;
color: #FF3939;
line-height: 18px;
`;

const LeftFloat = styled.div`
  position: relative;
  flex: 1;
`;

const RightFloat = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { autoFocus } = this.props;
    autoFocus &&
      setTimeout(() => {
        this.inputRef.current.focus();
      }, 100);
  }

  render() {
    const {
      children,
      value,
      placeholder,
      type,
      errorMsg,
      pageType,
      ...restProps
    } = this.props;
    const htmlForRandom = StringUtil.getRandom();

    return (
      <>
        <Line hasInput={!!value} isLoginPage={pageType === 'login'}>
          <LeftFloat>
            <label htmlFor={htmlForRandom} className="fake-placeholder">
              {placeholder}
            </label>
            {type === "password" ? (
              <Input
                id={htmlForRandom}
                type={type || "text"}
                ref={this.inputRef}
                {...restProps}
              />
            ) : (
              <Input
                id={htmlForRandom}
                value={value}
                type={type || "text"}
                ref={this.inputRef}
                {...restProps}
              />
            )}
          </LeftFloat>
          <RightFloat>{children}</RightFloat>
        </Line>
        {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
      </>

    );
  }
}
