import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import { get } from '#utils/globalVariable';
import { matchPath } from 'react-router-dom';

import Center from '#components/layout/Center';
import Logo from '#components/Logo';
import { BigPromptTip, MediumPromptTip } from '#components/PromptTip';
import TenantList from './components/TenantList';
import QYWXTenantList from './components/QYWXTenantList';
import MsgModal from '#components/MsgModal';
import ProtocolModal from '#components/ProtocolModal';

import {
  // loginStates,
  submitTenant,
  setLoginState,
  setTenantError,
  setNetworkError,
  handleFatalError,
  changeData,
  disagreePrmProtocol,
  agreePrmProtocol,
  setFatalError,
} from '#models/login/action';
import getTenantList from '#models/login/selectors/getTenantList';

const messages = defineMessages({
  welcomeBack: {
    id: 'SelectTenant.welcomeBack',
    defaultMessage: '欢 迎 !',
  },
  welcomeTip: {
    id: 'SelectTenant.welcomeTip',
    defaultMessage: '您的账号属于多个公司，请选择要登录的公司',
  },
  stopLogin: {
    id: 'SelectTenant.StopLogin',
    defaultMessage: '该租户已开启双因素认证，请使用密码登录',
  },
});

const LogoWrapper = styled.div`
  padding-bottom: 64px;
`;

const WelcomeBackWrapper = styled.div`
  margin: -32px 0 12px;
`;

const WelcomeTipWrapper = styled.div`
  margin: 0 0 24px;
`;

class SelectTenant extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      tenantList,
      onSelectTenantClick,
      tenantError,
      networkError,
      fatalError,
      onCloseMsgModal,
      dataList,
      ide,
      onChangeData,
      prmProtocol,
      onAgree,
      onDisagree
    } = this.props;

    const showHeader = !!matchPath(window.location.pathname, {
      path: '/auc/wxwork/multi-tenant',
      exact: true,
    });

    return (
      <Center
        showFooter={!showHeader}
      >
        {
          get('env') === 'WEB' && (
            <>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
              <WelcomeBackWrapper>
                <BigPromptTip>
                  {intl.formatMessage(messages.welcomeBack)}
                </BigPromptTip>
              </WelcomeBackWrapper>
              <WelcomeTipWrapper>
                <MediumPromptTip>
                  {intl.formatMessage(messages.welcomeTip)}
                </MediumPromptTip>
              </WelcomeTipWrapper>
            </>
          )
        }
        {
          ide ? <QYWXTenantList dataList={dataList} onClick={onChangeData} /> : <TenantList dataList={tenantList} onClick={onSelectTenantClick} />
        }
        <MsgModal
          show={
            (!!tenantError)
            || (!!networkError)
            || (!!fatalError)
          }
          msg={
            tenantError
            || networkError
            || fatalError
          }
          onClose={onCloseMsgModal}
        />
        <ProtocolModal
          show={
            (!!prmProtocol)
          }
          prmProtocol={prmProtocol}
          disagree={onDisagree}
          agree={onAgree}
        />
      </Center>
    );
  }
}

SelectTenant.propTypes = {
  tenantList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: PropTypes.string,
      selected: PropTypes.bool,
      user_type: PropTypes.string,
    }),
  ).isRequired,
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      company: PropTypes.string,
      selected: PropTypes.bool,
      userType: PropTypes.string,
    }),
  ).isRequired,
  onSelectTenantClick: PropTypes.func.isRequired,
  onChangeData: PropTypes.func.isRequired,
  tenantError: PropTypes.string,
  networkError: PropTypes.string,
  fatalError: PropTypes.string,
  onCloseMsgModal: PropTypes.func.isRequired,
  ide: PropTypes.number,
  prmProtocol: PropTypes.shape({
    ticket: PropTypes.string,
    crmDomain: PropTypes.string,
    protocol: PropTypes.arrayOf(
      PropTypes.shape({
        protocolName: PropTypes.string,
        contentUri: PropTypes.string,
        refuseTxt: PropTypes.string,
        agreeTxt: PropTypes.string,
      })
    )
  }),
  onDisagree: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired
};

SelectTenant.defaultProps = {
  tenantError: null,
  networkError: null,
  fatalError: null,
  ide: null,
  prmProtocol: null
};

// connect
function mapStatesToProps(state) {
  return {
    tenantList: getTenantList(state),
    tenantError: state.tenantError,
    fatalError: state.fatalError,
    prmProtocol: state.prmProtocol
  };
}

function mapDispatchToProps(dispatch) {
  const control = sessionStorage.getItem('controlStatus');
  return {
    onSelectTenantClick(id, mfaStatus) {
      // 如果开启双认证 则必须使用密码登录
      if (control === '2' && mfaStatus === '1') {
        dispatch(setFatalError(intl.formatMessage(messages.stopLogin)));
        return;
      }
      dispatch(submitTenant(id));
    },
    onCloseMsgModal() {
      dispatch(setTenantError(null));
      dispatch(setNetworkError(null));
      dispatch(setLoginState(null));
      dispatch(handleFatalError());
    },
    onChangeData(id) {
      dispatch(changeData(id));
    },
    onDisagree(protocol) {
      dispatch(disagreePrmProtocol(protocol))
    },
    onAgree(protocol) {
      dispatch(agreePrmProtocol(protocol))
    }
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps,
)(SelectTenant);
