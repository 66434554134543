import { call, put } from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';
import {
  loginStates,
  setPassword,
  setSecureCode,
  setNewPassword,
  setNewPasswordAgain,
  setResetPasswordLoading,
  setNewPasswordError,
  setLoginState,
} from '#models/login/action';
import getSelectedTenant from '#models/login/selectors/getSelectedTenant';
import { store } from '../index';

import { status, getResetPassword } from '../LoginService';

export default function* fetchResetPassword() {
  // 首先，需要本地检校，两次输入的新密码必须一致
  const state = store.getState();
  const {
    passport: passportId,
    secureCode,
    newPassword,
    newPasswordAgain,
  } = state;
  let { forgetPasswordReceiver, username } = state;
  const { id: tenantId } = getSelectedTenant(state);
  username = (forgetPasswordReceiver == null || forgetPasswordReceiver == undefined || forgetPasswordReceiver == '') ? username : forgetPasswordReceiver;
  const regex = /^[^\s]+$/;
  if (newPassword !== newPasswordAgain) {
    // 两次输入的密码不同
    // 发送错误
    yield put(setNewPasswordError(intl.formatMessage(getScodeMessages(1000001))));
  } else if (!regex.test(newPassword) || !regex.test(newPasswordAgain)) {
    // 输入的密码中存在空格
    yield put(setNewPasswordError(intl.formatMessage(getScodeMessages(1000008))));
  } else if (newPassword !== '') {
    // 两次密码相同

    // 设置Loading状态
    yield put(setResetPasswordLoading(true));

    // 调用修改密码的接口
    const res = yield call(getResetPassword, {
      username,
      passportId,
      newPassword,
      tenantId,
      secureCode,
    });

    // 清空Loading状态
    yield put(setResetPasswordLoading(false));

    if (res) {
      const { scode, error_msg: errorMsg } = res;
      switch (scode) {
        case status.OK:
          // 1、更新原用户的密码为newPassword;
          // 2、清空newPassword与newPasswordAgain;
          // 3、清空忘记密码页面需要的secureCode;
          // 4、清空错误提示
          // 5、跳转到重置密码成功页面
          // yield put(setPassword(newPassword)); //暂不提供携带最新密码跳转
          yield put(setNewPassword(''));
          yield put(setNewPasswordAgain(''));
          yield put(setSecureCode(''));
          yield put(setNewPasswordError(null));
          yield put(setLoginState(loginStates.RESET_PASSWORD_SUCCESSFULLY));
          break;
        default:
          // 设置newPasswordError
          yield put(setNewPasswordError(intl.formatMessage(getScodeMessages(scode), { errorMsg })));
          break;
      }
    }
  } else {
    yield put(setNewPasswordError(intl.formatMessage(getScodeMessages(100002))));
  }
}
