import {
  call,
  put,
} from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';
import {
  setForgetPasswordError,
  setNECaptchaValidate,
  setIsVerificationCodeLoading,
} from '../action';
import {
  getMsgCode,
  popupAuthCode,
  status,
} from '../LoginService';
import {
  store,
} from '../index';

export default function* fetchMsgCode() {
  const state = store.getState();
  const {
    smsName,
    NECaptchaValidate,
  } = state;
    // 对用户名进行检校
  if (smsName === '') {
    yield put(setForgetPasswordError(intl.formatMessage(getScodeMessages(1000005))));
    return;
  }
  // 前端不再做用户名格式校验
  const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  const regEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!reg.test(smsName) && !regEmail.test(smsName)) {
    yield put(setForgetPasswordError(intl.formatMessage(getScodeMessages(2041107))));
    return;
  }
  try {
    // 设置接口请求状态
    // yield put(setIsVerificationCodeLoading(true));
    // 请求获取短信验证码的接口
    const res = yield call(getMsgCode, {
      smsName,
      NECaptchaValidate,
    });
    yield put(setNECaptchaValidate(null));
    // 清空NECaptchaValidate
    // 保证只有call(popupAuthCode)时才会获取最新的NECaptchaValidate
    // 其他请求不带有NECaptchaValidate
    if (res) {
      switch (res.scode) {
        case status.OK:
          // 获取短信验证码成功
          // 1、清空忘记密码页错误
          // 2、根据结果判断是否需要显示图片识别码
          yield put(setForgetPasswordError(null));
          if (res.result.needCode === 1) {
            // const error = yield call(popupAuthCode);
            // if (error) {
            //   yield put(setForgetPasswordError(intl.formatMessage(getScodeMessages(1000006))));
            // }
            try {
              yield call(popupAuthCode);
            } catch (error) {
              yield put(setForgetPasswordError(intl.formatMessage(getScodeMessages(1000006))));
            }
          } else {
            // 设置接口请求状态
            yield put(setIsVerificationCodeLoading(true));
          }
          break;
        default:
          yield put(
            setForgetPasswordError(
              intl.formatMessage(
                getScodeMessages(res.scode),
                { errorMsg: res.error_msg },
              ),
            ),
          );
          break;
      }
    }
  } catch (err) {
    console.log(err);
  }
}
