// src/models/login/sagas/fetchMsgSend.js

import {
  call,
  put,
} from 'redux-saga/effects';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';

import {
  setVerificationCode,
  setForgetPasswordLoading,
  setMsgEmailLoginError,
  createTenantList,
  submitTenant,
  jumpToSelectTenant,
  setLoginLoading,
  setPassport,
  setPassportError,
  setTenantError,
  setFatalError,
} from '../action';
import {
  checkMsgEmailSend,
  status,
} from '../LoginService';
import {
  store,
} from '../index';

export default function* fetchMsgEamilLogin() {
  const state = store.getState();
  const {
    smsName,
    smsvificationCode,
    msgEmailSecCode,
  } = state;

  // 设置Loading状态
  // yield put(setForgetPasswordLoading(true));
  yield put(setLoginLoading(true));
  // 发起短信验证码检校请求
  try {
    // const params = queryString.parse(window.location.search);
    const res = yield call(checkMsgEmailSend, {
      smsName,
      smsvificationCode,
      secureCode: msgEmailSecCode,
      // ...params,
    });
    if (!res) {
      yield put(setLoginLoading(false));
      return;
    }
    if (res) {
      switch (res.scode) {
        case status.OK: {
          // 获取短信登录后的租户列表数据
          // 触发action 促使reducer数据更新
          yield put(setPassport(res.result.passport_id));
          yield put(setPassportError(null));
          yield put(setTenantError(null));
          yield put(createTenantList(res.result.tenant_list));
          localStorage.setItem('smsName', smsName);
          localStorage.setItem('lastLoginName', smsName);
          localStorage.setItem('lastLoginType', sessionStorage.getItem('status'));
          const tenantsNumber = res.result.tenant_list.length;
          const control = sessionStorage.getItem('controlStatus');
          if (tenantsNumber === 1) {
            if (control === '2' && res.result.tenant_list[0].mfa_status === '1') {
              yield put(setFatalError(
                intl.formatMessage(
                  getScodeMessages('2030081'), {
                    scode: '2030081',
                  },
                ),
              ));
              return;
            }
            yield put(submitTenant(res.result.tenant_list[0].id));
          } else if (tenantsNumber > 1) {
            yield put(jumpToSelectTenant());
            yield put(setLoginLoading(false));
          } else {
            console.error("There's no tenants for this passport.");
            yield put(setLoginLoading(false));
          }
          // 清空验证码
          yield put(setVerificationCode(''));
          break;
        }
        default:
          // 短信验证码检校失败，设置错误
          yield put(setLoginLoading(false));
          yield put(
            setMsgEmailLoginError(
              intl.formatMessage(
                getScodeMessages(res.scode), {
                  scode: res.scode,
                },
              ),
            ),
          );
          break;
      }
    }
  } catch (err) {
    yield put(setLoginLoading(false));
    // console.log(err);
  }
}
