/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import { get } from '#utils/globalVariable';
import ExternalLogin from '#components/ExternalLogin';
import Center from '#components/layout/Center';
import Logo from '#components/Logo';
import { BigPromptTip } from '#components/PromptTip';
import Input from '#components/Input';
import Button from '#components/Button';
import MsgModal from '#components/MsgModal';
import BrowserModal from '#components/BrowserModal';
import ForgetPassword from '#components/ForgetPassword';
import RegisterAndPrivacy from '#components/RegisterAndPrivacy';
import withTips from '#components/HOC/WithTips';
import queryString from "query-string";
import SmsLogin from '#pages/SmsLogin';

// import SSO from '#components/SSO';

import {
    setUsername,
    setPassword,
    submitLogin,
    setSSOError,
    setBrandError,
    setNetworkError,
    handleFatalError,
    clearUserName,
    protocalChanged
} from '#models/login/action';

const ieVersion = (function () {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        let version = userAgent.match(reIE)[1]
        var fIEVersion = parseFloat(version);
        if (fIEVersion == 7) {
            return 7;
        } else if (fIEVersion == 8) {
            return 8;
        } else if (fIEVersion == 9) {
            return 9;
        } else if (fIEVersion == 10) {
            return 10;
        } else {
            return 6;//IE版本<=7
        }
    } else if (isEdge) {
        return 'edge';//edge
    } else if (isIE11) {
        return 11; //IE11
    } else {
        return -1;//不是ie浏览器
    }
})()
const { smsenabled, passwordenabled, ssoenabled } = queryString.parse(window.location.search);
const isIE = typeof ieVersion === 'number' && ieVersion > -1; //判断是否IE<11浏览器

const messages = defineMessages({
    accountPlaceholder: {
        id: 'Login.accountPlaceholder',
        defaultMessage: '手机号/邮箱',
    },
    passwordPlaceholder: {
        id: 'Login.passwordPlaceholder',
        defaultMessage: '密码',
    },
    login: {
        id: 'Login.login',
        defaultMessage: '登 录',
    },
    loginPassWord: {
        id:	"Login.loginPassWord",
        defaultMessage: '密码登录',
    },
    smsLogin: {
    id: "Login.SmsLogin",
    defaultMessage: '验证码登录',
    }

});
const getWebTerminalInfo = () => {
    const env = get('env');
    if (env === 'WEB') {
        return true;
    }
    return false;
};
const getAppTerminalInfo = () => {
    const env = get('env');
    if (env === 'MOBILE') {
        return true;
    }
    return false;
};
const LogoWrapper = styled.div`
  padding-bottom: 37px;
  padding-top: 32px;
`;

const SignInTipWrapper = styled.div`
  margin: 0 0 8px;
`;
const PrivatizedLogin = styled.div`
  display: none;
`;
const GeneralLogin = styled.div`
  display: block;
`;

const ClearInputUserNameBtn = styled.a`
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  &:hover{
    background: rgba(0, 0, 0, 0.2);
 }
 
 &:before,
 &:after {
    content: ' ';
    position: absolute;
    height: 8px;
    width: 2px;
    background-color: #fff;
  }
  
  &:before {
    transform: rotate(45deg);
  }
  
  &:after {
    transform: rotate(-45deg);
  }
`

const Wrapper = styled.div`
  display:inline-block;
  font-size: 18px;
  padding-bottom: 4px;
  line-height: 28px;
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: 48px;
  :hover {
      font-weight:bold;
  };
`;
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 1
        };
      }
    componentDidMount() {
        // this.setState({
        //     status: sessionStorage.getItem("status")
        // })
        const status = sessionStorage.getItem("status")
        if(status==2) {
            this.setState({
                status:2
            })
            console.log(sessionStorage.getItem("status"),"status0000000")
        }else {
            this.setState({
                status:1
            })
        }
    }
    handlePassW = ()=> {
        this.setState({
            status:1
        },()=>{
            sessionStorage.setItem("status",this.state.status)
        })
    }
    handleSms=() => {
        this.setState({
            status: 2
        },()=>{
            sessionStorage.setItem("status",this.state.status)
        })
    }
    render() {
        sessionStorage.setItem("controlStatus",this.state.status)

        const {
            onLoginClick,
            onUsernameInput,
            onPasswordInput,
            onPressEnter,
            onClearUserName,
            username,
            password,
            checkBoxRes,
            buttonLoading,
            passportError,
            tenantError,
            registerAddressError,
            SSOError,
            brandError,
            networkError,
            fatalError,
            onCloseMsgModal,
            onProtoChanged,
        } = this.props
         const { status } = this.state;
        return (
            <>
            <Center
                showFooter
            >
                {
                    isIE && <BrowserModal ieVersion={ieVersion}></BrowserModal>
                }
                {
                    get('env') === 'WEB' && (
                        <LogoWrapper>
                            <Logo />
                        </LogoWrapper>
                    )
                }
                {
                 smsenabled==1&&passwordenabled==0?
                 <SignInTipWrapper>
                 <BigPromptTip>
                   {intl.formatMessage(messages.login)}
                 </BigPromptTip>
               </SignInTipWrapper>
                 :
                 smsenabled==1&&passwordenabled==1?
                <div>
                <Wrapper style={status===1||undefined?{ fontWeight: 'bold', borderBottom: '2px solid #0564F5' }:undefined}  onClick={this.handlePassW}>{intl.formatMessage(messages.loginPassWord)}</Wrapper>
                <Wrapper style={status===2?{ fontWeight: 'bold', borderBottom: '2px solid #0564F5' }: undefined}  onClick={this.handleSms}>{intl.formatMessage(messages.smsLogin)}</Wrapper>
                </div>
                :
                <SignInTipWrapper>
                <BigPromptTip>
                  {intl.formatMessage(messages.login)}
                </BigPromptTip>
              </SignInTipWrapper>
                }
                {status===1&&passwordenabled==1?(
                <div>
                <div>
                    <Input
                        value={username}
                        placeholder={intl.formatMessage(messages.accountPlaceholder)}
                        onChange={onUsernameInput}
                        onKeyUp={buttonLoading ? null : onPressEnter}
                        type="text"
                        name="username"
                        pageType="login"
                        data-ta-key="email_input"
                        // autoFocus={get('env') === 'WEB' && !isIE}
                    >
                        {username && <ClearInputUserNameBtn onClick={onClearUserName} />}
                    </Input>
                    <Input
                        value={password}
                        placeholder={intl.formatMessage(messages.passwordPlaceholder)}
                        onChange={onPasswordInput}
                        onKeyUp={buttonLoading ? null : onPressEnter}
                        type="password"
                        pageType="login"
                        errorMsg={passportError || tenantError}
                        data-ta-key="password_input"
                    >
                        <ForgetPassword />
                    </Input>
                </div>
                {/* OEM修改项请勿动 */}
                <RegisterAndPrivacy isLoginPage={true} handleChange={onProtoChanged} />
                {
                    getWebTerminalInfo() &&
                    (<Button
                        loading={buttonLoading}
                        active={username && password}
                        onClick={buttonLoading ? null : onLoginClick}
                        isLoginPage={true}
                        data-ta-key="login_btn"
                    >
                        {intl.formatMessage(messages.login)}
                    </Button>
                    )
                }
                {
                    getAppTerminalInfo() &&
                    (<GeneralLogin>
                        <Button
                            loading={buttonLoading}
                            active={username && password && checkBoxRes}
                            onClick={buttonLoading ? null : onLoginClick}
                            isLoginPage={true}
                            data-ta-key="login_btn"
                        >
                            {intl.formatMessage(messages.login)}
                        </Button>
                    </GeneralLogin>)
                }
                {
                    getAppTerminalInfo() &&
                    (
                        <PrivatizedLogin>
                            <Button
                                loading={buttonLoading}
                                active={username && password}
                                onClick={buttonLoading ? null : onLoginClick}
                                isLoginPage={true}
                                data-ta-key="login_btn"
                            >
                                {intl.formatMessage(messages.login)}
                            </Button>
                        </PrivatizedLogin>
                    )
                }
                </div>
                )
                : <SmsLogin/> }

                <ExternalLogin />
                <MsgModal
                    show={
                        (!!SSOError)
                        || (!!brandError)
                        || (!!registerAddressError)
                        || (!!networkError)
                        || (!!fatalError)
                    }
                    msg={
                        SSOError
                        || brandError
                        || registerAddressError
                        || networkError
                        || fatalError
                    }
                    onClose={onCloseMsgModal}
                />
            </Center>
            </>
        )
    }
}

Login.propTypes = {
    onLoginClick: PropTypes.func.isRequired,
    onUsernameInput: PropTypes.func.isRequired,
    onPasswordInput: PropTypes.func.isRequired,
    onCloseMsgModal: PropTypes.func.isRequired,
    onPressEnter: PropTypes.func.isRequired,
    onClearUserName: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    buttonLoading: PropTypes.bool.isRequired,
    passportError: PropTypes.string,
    tenantError: PropTypes.string,
    registerAddressError: PropTypes.string,
    SSOError: PropTypes.string,
    brandError: PropTypes.string,
    networkError: PropTypes.string,
    fatalError: PropTypes.string,
    checkBoxRes: PropTypes.bool,
};

Login.defaultProps = {
    passportError: null,
    tenantError: null,
    registerAddressError: null,
    SSOError: null,
    brandError: null,
    networkError: null,
    fatalError: null,
};

// connect组件
function mapStateToProps(state) {
    return {
        username: state.username,
        password: state.password,
        buttonLoading: state.loginLoading,
        passportError: state.passportError,
        tenantError: state.tenantError,
        registerAddressError: state.registerAddressError,
        SSOError: state.SSOError,
        brandError: state.brandError,
        networkError: state.networkError,
        fatalError: state.fatalError,
        globalError: state.globalError,
        checkBoxRes: state.checkBoxRes,
    };
}

function mapDispatchToProps(dispatch) {
    return {

        onLoginClick() {
            dispatch(submitLogin());
        },
        onUsernameInput(e) {
            dispatch(setUsername(e.target.value.trim()));
        },
        onProtoChanged(e) {
            dispatch(protocalChanged(e.target.checked))
        },
        onPasswordInput(e) {
            dispatch(setPassword(e.target.value));
        },
        onCloseMsgModal() {
            dispatch(setSSOError(null));
            dispatch(setBrandError(null));
            dispatch(setNetworkError(null));
            dispatch(handleFatalError());
        },
        onClearUserName() {
            dispatch(clearUserName());
        },
        onPressEnter(e) {
            if (e.keyCode === 13) {
                dispatch(submitLogin());
            }
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTips(Login));
