/* eslint-disable */
import BrowserModal from '#components/BrowserModal';
import Button from '#components/Button';
import ExternalLogin from '#components/ExternalLogin';
import ForgetPassword from '#components/ForgetPassword';
import withTips from '#components/HOC/WithTips';
import Input from '#components/Input';
import Logo from '#components/Logo';
import MsgModal from '#components/MsgModal';
import { BigPromptTip } from '#components/PromptTip';
import RegisterAndPrivacy from '#components/RegisterAndPrivacy';
import Center from '#components/layout/Center';
import { get } from '#utils/globalVariable';
import { defineMessages } from '#utils/i18n/intl';
// import Scada from '#utils/sentry/index';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import queryString from "query-string";
import AvatarMonitor from '#utils/sentry/AvatarMonitor';
import ProtocolModal from '#components/ProtocolModal';
import SmsLogin from '#pages/SmsLogin';
import LoadingUrl from '#static/img/loading_l.gif'


// import SSO from '#components/SSO';


import {
  clearUserName,
  handleFatalError,
  protocalChanged,
  setBrandError,
  setNetworkError,
  setPassword,
  setSSOError,
  setUsername,
  submitLogin,
  disagreePrmProtocol,
  agreePrmProtocol,
  setSmsCode,
  setMsgEmailLoginError,
  setPassportError,
  setTenantError,
} from '#models/login/action';
import { fetchEncryptedValues, fetchAuthnOption } from '#models/login/LoginService'
// export const scada = new Scada();
AvatarMonitor.getInstance().init();

const ieVersion = (function () {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    let version = userAgent.match(reIE)[1]
    var fIEVersion = parseFloat(version);
    if (fIEVersion == 7) {
      return 7;
    } else if (fIEVersion == 8) {
      return 8;
    } else if (fIEVersion == 9) {
      return 9;
    } else if (fIEVersion == 10) {
      return 10;
    } else {
      return 6;//IE版本<=7
    }
  } else if (isEdge) {
    return 'edge';//edge
  } else if (isIE11) {
    return 11; //IE11
  } else {
    return -1;//不是ie浏览器
  }
})()

const isMiniApp = queryString.parse(window.location.search) && queryString.parse(window.location.search).login_type === 'miniapp'

const isIE = typeof ieVersion === 'number' && ieVersion > -1; //判断是否IE<11浏览器

const messages = defineMessages({
  accountPlaceholder: {
    id: 'Login.accountPlaceholder',
    defaultMessage: '手机号/邮箱',
  },
  accountPlaceholder2: {
    id: 'Login.accountPlaceholder2',
    defaultMessage: '账户',
  },
  passwordPlaceholder: {
    id: 'Login.passwordPlaceholder',
    defaultMessage: '密码',
  },
  login: {
    id: 'Login.login',
    defaultMessage: '登 录',
  },
  loginPassWord: {
    id:	"Login.loginPassWord",
    defaultMessage: '密码登录',
  },
  smsLogin: {
    id: "Login.VerificationLogin",
    defaultMessage: '验证码登录',
  }
});
const getWebTerminalInfo = () => {
  const env = get('env');
  if (env === 'WEB') {
    return true;
  }
  return false;
};
const getAppTerminalInfo = () => {
  const env = get('env');
  if (env === 'MOBILE') {
    return true;
  }
  return false;
};
const LogoWrapper = styled.div`
  padding-bottom: 37px;
  // padding-top: 32px;
`;

const SignInTipWrapper = styled.div`
  margin: 0 0 8px;
`;
const PrivatizedLogin = styled.div`
  display: none;
`;
const GeneralLogin = styled.div`
  display: block;
`;
const Wrapper = styled.div`
  display:inline-block;
  font-size: 18px;
  padding-bottom: 4px;
  line-height: 28px;
  cursor: pointer;
  margin-bottom: 8px;
  margin-right: 48px;
  :hover {
      font-weight:bold;
  };
`;
const ClearInputUserNameBtn = styled.a`
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  &:hover{
    background: rgba(0, 0, 0, 0.2);
 }
 
 &:before,
 &:after {
    content: ' ';
    position: absolute;
    height: 8px;
    width: 2px;
    background-color: #fff;
  }
  
  &:before {
    transform: rotate(45deg);
  }
  
  &:after {
    transform: rotate(-45deg);
  }
`;
const Shielder = styled.div`
  pointer-events: ${props => (props.inputLoading ? "none" : "unset")};
  filter: ${props => (props.inputLoading ? "blur(20px)" : "none")};
  -webkit-filter: ${props => (props.inputLoading ? "blur(20px)" : "none")};
  transform: ${props => (props.inputLoading ? "translateZ(0)" : "none")}
`;
const LoadingIcon = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin: auto;
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        onlyPass: false,
        status: 1,
        inputLoading: false,
    };
  }
componentDidMount() {
  const {
    ssoKey,
    sub_domain
  } = queryString.parse(window.location.search)
  // 一期改造登录
  fetchEncryptedValues({ setPasswordKey: true })

  AvatarMonitor.getInstance().setOptions({
    platform: (get("env") || "").toLowerCase(),
  });
  // 获取认证方式
  const status = localStorage.getItem("lastLoginType")
  if(status==2 && this.props.hasEntryCode && !isMiniApp) {
    this.setState({ status:2 })
  }else {
    this.setState({ status:1 })
  }
  sessionStorage.setItem("controlStatus", status)
  sessionStorage.setItem("status", status)
  if(ssoKey || sub_domain){
    this.setState({inputLoading: true})
    fetchAuthnOption({ ssoKey: ssoKey, subDomain: sub_domain }, ()=>{
      this.setState({inputLoading: false})
    })
  }
}
componentDidUpdate(prevProps, prevState) {
  if (prevState.status !== this.state.status) {
    // 当 status 改变时执行的操作
    sessionStorage.setItem("controlStatus", this.state.status)
    sessionStorage.setItem("status", this.state.status)
    // 清空验证码信息和报错信息
    this.props.onClearErrorAndCode()
  }
}
handlePassW = ()=> {
    this.setState({
        status:1
    })
}
handleSms=() => {
    this.setState({
        status: 2
    })
}


  render() {
    const {
      onLoginClick,
      onUsernameInput,
      onPasswordInput,
      onPressEnter,
      onClearUserName,
      username,
      password,
      checkBoxRes,
      buttonLoading,
      passportError,
      tenantError,
      registerAddressError,
      SSOError,
      brandError,
      networkError,
      fatalError,
      onCloseMsgModal,
      onProtoChanged,
      loginType,
      prmProtocol,
      onAgree,
      onDisagree,
      hasEntryCode,
      onClearErrorAndCode,
    } = this.props

    let hideForgetBtn = loginType != 'miniapp'
    const { onlyPass, status, inputLoading } = this.state;
    return (
      <Center
        showFooter
      >
        {
          isIE && <BrowserModal ieVersion={ieVersion}></BrowserModal>
        }
        {
          get('env') === 'WEB' && (
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          )
        }
        <div style={{position:'relative', overflow: inputLoading ? 'unset' : 'hidden'}}>
          {
            inputLoading ?
             <LoadingIcon src={LoadingUrl} /> : null
          }
          <Shielder inputLoading={inputLoading}>
            {
              !hasEntryCode || isMiniApp ?
              <SignInTipWrapper>
                <BigPromptTip>
                  {intl.formatMessage(messages.login)}
                </BigPromptTip>
              </SignInTipWrapper>:
              <div>
              <Wrapper style={status===1||undefined?{ fontWeight: 'bold', borderBottom: '2px solid #0564F5' }:undefined}  onClick={this.handlePassW}>{intl.formatMessage(messages.loginPassWord)}</Wrapper>
              <Wrapper style={status===2?{ fontWeight: 'bold', borderBottom: '2px solid #0564F5' }: undefined}  onClick={this.handleSms}>{intl.formatMessage(messages.smsLogin)}</Wrapper>
              </div>
            }
            {status===1 || !hasEntryCode?
              <>
              <div>
                <Input
                  value={username}
                  placeholder={intl.formatMessage(isMiniApp ? messages.accountPlaceholder2 : messages.accountPlaceholder)} // 小程序登录因为存在违规收集手机号判定风险，所以修改提示为账户
                  onChange={onUsernameInput}
                  onKeyUp={buttonLoading ? null : onPressEnter}
                  type="text"
                  name="username"
                  pageType="login"
                  data-ta-key="email_input"
                  autoFocus={get('env') === 'WEB' && !isIE}
                >
                  {username && <ClearInputUserNameBtn onClick={onClearUserName} />}
                </Input>
                <Input
                  value={password}
                  placeholder={intl.formatMessage(messages.passwordPlaceholder)}
                  onChange={onPasswordInput}
                  onKeyUp={buttonLoading ? null : onPressEnter}
                  type="password"
                  pageType="login"
                  errorMsg={passportError || tenantError}
                  data-ta-key="password_input"
                >
                  {hideForgetBtn && <ForgetPassword />}
                </Input>
              </div>
              {/* OEM修改项请勿动 */}
              <RegisterAndPrivacy isLoginPage={true} handleChange={onProtoChanged} />

              {
                getWebTerminalInfo() &&
                (<Button
                  loading={buttonLoading}
                  active={username && password}
                  onClick={buttonLoading ? null : onLoginClick}
                  isLoginPage={true}
                  data-ta-key="login_btn"
                >
                  {intl.formatMessage(messages.login)}
                </Button>
                )
              }
              {
                getAppTerminalInfo() &&
                (<GeneralLogin>
                  <Button
                    loading={buttonLoading}
                    active={username && password && checkBoxRes}
                    onClick={buttonLoading ? null : onLoginClick}
                    isLoginPage={true}
                    data-ta-key="login_btn"
                  >
                    {intl.formatMessage(messages.login)}
                  </Button>
                </GeneralLogin>)
              }
              {
                getAppTerminalInfo() &&
                (
                  <PrivatizedLogin>
                    <Button
                      loading={buttonLoading}
                      active={username && password}
                      onClick={buttonLoading ? null : onLoginClick}
                      isLoginPage={true}
                      data-ta-key="login_btn"
                    >
                      {intl.formatMessage(messages.login)}
                    </Button>
                  </PrivatizedLogin>
                )
              }</>
              :<SmsLogin/>}
          </Shielder>
          <ExternalLogin />
        </div>
        <MsgModal
          show={
            (!!SSOError)
            || (!!brandError)
            || (!!registerAddressError)
            || (!!networkError)
            || (!!fatalError)
          }
          msg={
            SSOError
            || brandError
            || registerAddressError
            || networkError
            || fatalError
          }
          onClose={onCloseMsgModal}
        />
        <ProtocolModal
          show={
            (!!prmProtocol)
          }
          prmProtocol={prmProtocol}
          disagree={onDisagree}
          agree={onAgree}
        />
      </Center>
    )
  }
}

Login.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onUsernameInput: PropTypes.func.isRequired,
  onPasswordInput: PropTypes.func.isRequired,
  onCloseMsgModal: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func.isRequired,
  onClearUserName: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  passportError: PropTypes.string,
  tenantError: PropTypes.string,
  registerAddressError: PropTypes.string,
  SSOError: PropTypes.string,
  brandError: PropTypes.string,
  networkError: PropTypes.string,
  fatalError: PropTypes.string,
  checkBoxRes: PropTypes.bool,
  prmProtocol: PropTypes.shape({
    ticket: PropTypes.string,
    crmDomain: PropTypes.string,
    protocol: PropTypes.arrayOf(
      PropTypes.shape({
        protocolName: PropTypes.string,
        contentUri: PropTypes.string,
        refuseTxt: PropTypes.string,
        agreeTxt: PropTypes.string,
      })
    )
  }),
  onDisagree: PropTypes.func.isRequired,
  onAgree: PropTypes.func.isRequired,
  hasEntryCode: PropTypes.bool.isRequired,
  onClearErrorAndCode: PropTypes.func.isRequired,
};

Login.defaultProps = {
  passportError: null,
  tenantError: null,
  registerAddressError: null,
  SSOError: null,
  brandError: null,
  networkError: null,
  fatalError: null,
  prmProtocol: null
};

// connect组件
function mapStateToProps(state) {
  return {
    username: state.username,
    password: state.password,
    buttonLoading: state.loginLoading,
    passportError: state.passportError,
    tenantError: state.tenantError,
    registerAddressError: state.registerAddressError,
    SSOError: state.SSOError,
    brandError: state.brandError,
    networkError: state.networkError,
    fatalError: state.fatalError,
    globalError: state.globalError,
    checkBoxRes: state.checkBoxRes,
    loginType: state.loginType,
    passwordKey: state.passwordKey,
    prmProtocol: state.prmProtocol,
    hasEntryCode: state.hasEntryCode
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLoginClick() {
      dispatch(submitLogin());
    },
    onUsernameInput(e) {
      dispatch(setUsername(e.target.value.trim()));
    },
    onProtoChanged(e) {
      dispatch(protocalChanged(e.target.checked))
    },
    onPasswordInput(e) {
      dispatch(setPassword(e.target.value));
    },
    onCloseMsgModal() {
      dispatch(setSSOError(null));
      dispatch(setBrandError(null));
      dispatch(setNetworkError(null));
      dispatch(handleFatalError());
    },
    onClearUserName() {
      dispatch(clearUserName());
    },
    onPressEnter(e) {
      if (e.keyCode === 13 && get('env') == 'WEB') {
        dispatch(submitLogin());
      }
    },
    onDisagree(protocol) {
      dispatch(disagreePrmProtocol(protocol))
    },
    onAgree(protocol) {
      dispatch(agreePrmProtocol(protocol))
    },
    onClearErrorAndCode() {
      dispatch(protocalChanged(false))
      dispatch(setSmsCode(''))
      dispatch(setMsgEmailLoginError(null))
      dispatch(setPassword(''))
      dispatch(setPassportError(null))
      dispatch(setTenantError(null))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTips(Login));
