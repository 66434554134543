/* eslint-disable */
import queryString from "query-string";
import {
  put
} from "redux-saga/effects";
import {
  matchPath
} from "react-router-dom";
import JSEncrypt from "jsencrypt";
import {
  loginStates,
  setLoginState,
  setLoginLoading,
  setAgreeLoading,
  setForgetPasswordLoading,
  setShowVerificationCodeHelpMessage,
  setNECaptchaValidate,
  setAuthCodeInstance,
  fetchVerificationCode,
  fetchMsgCode,
  fetchPassport,
  setGlobalPasswordKey,
  setFatalError,
  setTenantError,
  setHasEntryCode,
  sendMsgEmail,
  setNewPasswordError,
  setPassportError, 
  setMsgEmailLoginError, 
  setForgetPasswordError
} from "./action";
import {
  Service,
  JSONP
} from "../Service";
import {
  store
} from "./index";
import {
  get
} from "#utils/globalVariable";
import getAppLocale from '#utils/i18n/getAppLocale';
import {
  getTimestamp,
  loadScript
} from "#utils/NECaptchaUtil";
import intl from "react-intl-universal";
import { defineMessages } from "#utils/i18n/intl";

const messages = defineMessages({
  historyTips: {
    id: "prm.login.agreement.reject.tips",
    defaultMessage: "历史版本APP不支持部分协议操作，请更新至最新版本"
  }
});

const podHost = transformToPodHost(
  window.location.origin ||
  `${window.location.protocol}//${window.location.host}`
);
// const podForgetPasswordUrl = `${podHost}/global/password-forget.action`;
// const podRegisterUrl = `${podHost}/global/register.action`;
const podPrivacyPolicyUrl = "http://www.xiaoshouyi.com/privacyagreement";

function transformToPodHost(host) {
  return host.replace(/login/, "crm");
}

function mobileCallback(actionType, actionPayload) {
  console.log({
    action: actionType,
    data: actionPayload
  });

  if (window.postMessage) {
    window.postMessage(
      JSON.stringify({
        action: actionType,
        data: actionPayload
      })
    );
  }
  if (window.xsyPostMessage) {
    window.xsyPostMessage(
      JSON.stringify({
        action: actionType,
        data: actionPayload
      })
    );
  }
}

/**
 * 获取公钥加密后的结果
 * 开启密码加密功能
 * @param {object} needEncryptValues 待加密的密码列表
 * @return {object} 加密后的密码列表
 */
function encryptValue(resData, needEncryptValues, needPromise) {
  const { pwdSwitch, key } = resData;
  const encryptor = new JSEncrypt();
  if (!pwdSwitch) {
    return Promise.resolve(needEncryptValues);
  };
  const result = {};
  encryptor.setPublicKey(key);
  Object.keys(needEncryptValues).forEach(key => {
    result[key] = encryptor.encrypt(needEncryptValues[key]);
  })
  if (needPromise) {
    return Promise.resolve(result);
  }
  return result;
}

export function fetchEncryptedValues(needEncryptValues = {}) {
  const { passwordKey } = store.getState();
  if (passwordKey) {
    return encryptValue(passwordKey, needEncryptValues, true);
  } else {
    return Service.get("/auc/passport/password-key").then(res => {
      if (res.scode === '200') {
        if (needEncryptValues.setPasswordKey) {
          store.dispatch(setGlobalPasswordKey(res.result));
        } else {
          return encryptValue(res.result, needEncryptValues);
        }
      }
    }).catch(error => {
      console.error(error)
      return {}
    })
  }
}

// 获取认证方式
export async function fetchAuthnOption(params = {}, callback) {
  try {
    const res = await Service.get("/auc/authn/options", { params, timeout: 10000 });
    const flag = !!res.result?.standardAuth?.verifyCodeAuthn?.enable;
    store.dispatch(setHasEntryCode(flag));
  } catch (error) {
    console.error(error)
    store.dispatch(setHasEntryCode(false));
  } finally {
    callback();
  }
}

export const status = {
  OK: "200",
  REDIRECT: "302",
  FIRST_LOGIN: "2030034",
  RESET_PASSWORD_1: "2030028",
  RESET_PASSWORD_2: "2030029",
  RESET_PASSWORD_3: "2030030",
  RESET_PASSWORD_4: "2030031",
  RESET_PASSWORD_5: "2030014",
  RESET_PASSWORD_6: "2030013",
  RESET_PASSWORD_7: "2030063",
  PASSWORD_EXPIRED: "2030105",
  INVALID_SESSION: "2030072",
  AUTHCODE: '2030007',
  PRM_PROTOCOL: '1030010',
};

export function getPassportService({
  username,
  password,
  NECaptchaValidate,
  mobileParams
}) {
  return fetchEncryptedValues({
    password
  }).then(({
    password
  }) => {
    return Service.post("/auc/login", {
      login_name: username,
      password,
      NECaptchaValidate,
      ...mobileParams
    });
  })

}

export function getTenantService({
  userType,
  encryptionKey,
  tenantId,
  passportId,
  mobileParams,
  mfaType
}) {
  return Service.post("/auc/oauth2/authorize-code", {
    user_type: userType,
    encryption_key: encryptionKey,
    tenant_id: tenantId,
    passport_id: passportId,
    mfa_type: mfaType,
    ...mobileParams
  });
}
// 短信验证码方式选择租户登录界面
export function getSmsService({
  userType,
  encryptionKey,
  tenantId,
  passportId,
  mobileParams
}) {
  return Service.post("/auc/smscode/tenant/auth", {
    user_type: userType,
    encryption_key: encryptionKey,
    tenant_id: tenantId,
    passport_id: passportId,
    ...mobileParams
  })
}

// 企微认证接口
export function getQYWXTenant({
  code,
  login_type,
  tenant_id,
}) {
  return window.location.replace(`/auc/wxwork/authorize?code=${code}&login_type=${login_type}&tenant_id=${tenant_id}`);
}
export function getRegisterAddressService() {
  return Service.get("/auc/register/global_pod");
}

export function getSSOInfoService({
  ssoId,
  type
}) {
  return Service.get(`/auc/sso/${ssoId}`, {
    params: {
      type
    }
  });
}

export function getBrandInfoService({
  subDomain
}) {
  return Service.get("/auc/login-page-element/get-by-domain-name", {
    params: {
      sub_domain: subDomain
    }
  });
}

export function getPasswordRules({
  passportId,
  tenantId
}) {
  return Service.get("/auc/passport/password-rule", {
    params: {
      passport_id: passportId,
      tenant_id: tenantId
    }
  });
}

export function getResetPassword({
  username,
  passportId,
  newPassword,
  tenantId,
  secureCode
}) {
  return fetchEncryptedValues({
    newPassword
  }).then(({
    newPassword
  }) => {
    return secureCode ?
      Service.post("/auc/passport/password-forget-reset", {
        login_name: username,
        passport_id: passportId,
        new_password: newPassword,
        secure_code: secureCode
      }) :
      Service.post("/auc/passport/password-reset", {
        login_name: username,
        passport_id: passportId,
        new_password: newPassword,
        tenant_id: tenantId
      });
  })
}

export function needAuthCode({
  username
}) {
  return Service.get("/auc/passport/captcha-need", {
    params: {
      login_name: username
    }
  });
}

export function getVerificationCode({
  username,
  NECaptchaValidate
}) {

  return fetchEncryptedValues({
    username
  }).then(({
    username
  }) => {
    return Service.post("/auc/passport/captcha-fetch", {
      login_name: username,
      NECaptchaValidate
    })
  });
  // return Service.post("/auc/passport/captcha-fetch", {
  //   login_name: username,
  //   NECaptchaValidate
  // });
}
// 验证码登录发送验证码接口
export function getMsgCode({
  smsName,
  NECaptchaValidate
}) {
  return Service.post('/auc/smscode/send-captcha', {
    login_name: smsName,
    verification_code: NECaptchaValidate
  });
}

// 登录页发送短信验证码或邮件验证码
export function getMsgEmailCode({
  smsName,
  NECaptchaValidate
}) {
  return Service.post('/auc/authn/verifycode/send-captcha', {
    login_name: smsName,
    verification_code: NECaptchaValidate
  });
}

// 忘记密码 短信验证接口请求
export function checkVerificationCode({
  username,
  verificationCode
}) {
  return fetchEncryptedValues({
    username
  }).then(({
    username
  }) => {
    return Service.post("/auc/passport/captcha-check", {
      login_name: username,
      captcha: verificationCode
    });
  });
}


// 短信验证码 短信验证接口请求
export function checkMsgSend({
  smsName,
  smsvificationCode,
}) {
  // 短信
  return Service.post("/auc/smscode/auth", {
    login_name: smsName,
    captcha: smsvificationCode,
  })
}

// 短信或邮箱验证码验证动作
export function checkMsgEmailSend({
  smsName,
  smsvificationCode,
  secureCode,
}) {
  // 短信
  return Service.post("/auc/authn/verifycode", {
    login_name: smsName,
    captcha: smsvificationCode,
    secure_code: secureCode
  })
}

export function popupAuthCode() {
  const {
    authCodeInstance,
    verificationCodeInputRef
  } = store.getState();
  const local = getAppLocale().currentLocale;
  let hasVerified = false;
  if (authCodeInstance) {
    authCodeInstance.popUp();
  } else {
    const url = `https://cstaticdun.126.net/load.min.js?t=${getTimestamp(
      1 * 60 * 1000
    )}`;
    loadScript(url, () => {
      // 进行初始化验证码等后续逻辑
      initNECaptcha({
        captchaId: "089d0ae009af40f3804169211331d8b8",
        element: "#NECaptchaSlot",
        mode: "popup",
        width: "320px",
        lang: local === 'en' ? 'en' : 'zh-CN',
        onVerify(err, data) {
          if (err) {
            console.log("验证码验证失败，请重新滑动验证码");
            hasVerified = false;
            return;
          }
          hasVerified = true;
          store.dispatch(setNECaptchaValidate(data.validate));
        },
        onClose() {
          if (hasVerified) {
            hasVerified = false;
            const forgetPwdPage = !!matchPath(window.location.pathname, {
              path: "/auc/password-forget",
              exact: true
            });
            // 发送短信验证
            const {
              smsenabled,
              passwordenabled,
            } = queryString.parse(window.location.search);
            const control = sessionStorage.getItem('controlStatus');
            const status = sessionStorage.getItem('status');
            let result;
            if (smsenabled == 1 && passwordenabled == 1) {
              result = control == 2;
            } else if (status == 2) {
              result = control == 2;
            } else if (passwordenabled == 1) {
              result = passwordenabled == 0;
            } else if (smsenabled == 1) {
              result = smsenabled == 1;
            } else {
              result = smsenabled == 1;
            }
            // 匹配路由如果是滑块校验否则直接请求登录接口
            if (forgetPwdPage) {
              store.dispatch(fetchVerificationCode());
            } else if (result) {
              // 此处切入点自动焦点验证码
              verificationCodeInputRef?.focus()
              // store.dispatch(fetchMsgCode());
              store.dispatch(sendMsgEmail());
              
            } else {
              store.dispatch(fetchPassport());
            }
          }
          const {
            authCodeInstance
          } = store.getState();
          authCodeInstance.refresh();
        }
      },
        function (instance) {
          instance.popUp();
          store.dispatch(setAuthCodeInstance(instance));
          console.log("验证码初始化成功,等待背景图片与验证相关信息的加载");
        },
        err => {
          console.log("验证码初始化失败");
          console.error(err);
        }
      );
    });
  }
}

export function redirectSelfPage(url) {
  window.location.assign(url);
}

export function redirectNewPage(url) {
  window.open(url, "_blank");
}

export function getBFFService(url) {
  return new Promise((resolve, reject) => {
    JSONP(url, (err, data) => {
      if (err) {
        return reject(err);
      }
      if (data) {
        return resolve(data);
      }
      return 1;
    });
    return 0;
  });
}

export function parseQuery(search) {
  return queryString.parse(search);
}

export function* jumpToForgetPassword({
  routerHistory
}) {
  // 清空Loading状态
  yield put(setForgetPasswordLoading(false));

  // store.dispatch(setLoginState(null));
  yield put(setLoginState(null));
  routerHistory.push(`/auc/password-forget${window.location.search}`);

  const env = get("env");
  switch (env) {
    case "MOBILE":
      mobileCallback("authForgetPassword");
      break;
    default:
      break;
  }
}

export function* jumpToLogin({
  routerHistory
}) {
  // 清空Loading状态
  yield put(setLoginLoading(false));
  // 原有基础上
  // 返回登录页清空登录时的报错信息
  yield put(setPassportError(null));
  yield put(setTenantError(null));
  yield put(setMsgEmailLoginError(null));
  // 返回登录页清空验证码的报错信息
  yield put(setForgetPasswordError(null));
  // 返回登录页清空重置密码的报错信息
  yield put(setNewPasswordError(null));

  const env = get("env");
  switch (env) {
    case "WEB":
    case "MOBILE":
      // store.dispatch(setLoginState(null));
      yield put(setLoginState(null));
      routerHistory.push(`/auc/oauth2/auth${window.location.search}`);
      break;
    case "ERROR": {
      const newURL = window.location.origin + window.location.search;
      window.location.assign(newURL);
    }
      break;
    default:
      break;
  }
}

export function* jumpToSelectTenant() {
  yield put(setLoginState(loginStates.SELECT_TENANT));
  const env = get("env");
  switch (env) {
    case "MOBILE":
      mobileCallback("authSelectTenant");
      break;
    default:
      break;
  }
}

export function register() {
  const {
    registerAddress
  } = store.getState();
  const podRegisterUrl = `${registerAddress}/global/register.action`;
  const env = get("env");
  switch (env) {
    case "WEB":
      redirectSelfPage(podRegisterUrl);
      break;
    case "MOBILE":
      mobileCallback("register", registerAddress);
      break;
    default:
      console.error("Invalid env.");
  }
}

export function* jumpAuthentication() {
  yield put(setLoginState(loginStates.AUTHENTICATION));

  const env = get("env");
  switch (env) {
    case "MOBILE":
      mobileCallback("authentication");
      break;
    default:
      break;
  }
}
export function jumpQYWXAuthentication() {
  const env = get("env");
  switch (env) {
    case "MOBILE":
      mobileCallback("authentication");
      break;
    default:
      break;
  }
}

export function jumpToPrivacyPolicy() {
  const env = get("env");
  switch (env) {
    case "WEB":
      redirectNewPage(podPrivacyPolicyUrl);
      break;
    case "MOBILE":
      mobileCallback("privacy");
      break;
    default:
      console.error("Invalid env.");
  }
}

export function* jumpToVerificationCodeHelpMessage({
  payload: open
}) {
  // console.log('Open Modal:');
  // console.log(open);
  if (open) {
    const env = get("env");
    switch (env) {
      case "WEB":
        yield put(setShowVerificationCodeHelpMessage(true));
        break;
      case "MOBILE":
        mobileCallback("noVerifyCodeMessage");
        break;
      default:
        console.error("Invalid env.");
    }
  } else {
    yield put(setShowVerificationCodeHelpMessage(false));
  }
}

export function handleFatalError({
  routerHistory
}) {
  const {
    fatalError
  } = store.getState();
  if (fatalError) {
    window.location.reload();
    const env = get("env");

    if (env === "MOBILE") {
      mobileCallback("authRefresh");
    }
  }
}

export function* jumpToTOTP() {
  yield put(setLoginState(loginStates.AUTHTOTP));

  const env = get("env");
  switch (env) {
    case "MOBILE":
      mobileCallback("authtotp");
      break;
    default:
      break;
  }
}

export function getAuthenticationType(params) {
  return Service.post("/auc/mfa/list", params);
}

/**
 * 标准登录-同意
 * 单点登录-同意，Protocol界面直接跳转，不在这里写
 */
export function* getTenantSuccess(data) {
  console.log('已经执行getTenantSuccess方法,打印当作参数传过来的bff返回结果====', data)
  const queryParams = queryString.parse(window.location.search); // 获取search中的属性
  console.log('打印url的search====', queryParams);
  // 判断是不是在小程序环境中运行
  if (queryParams && queryParams.login_type === 'miniapp') {
    console.log('开始发送小程序postMessage===')
    window.wx.miniProgram.postMessage({
      data: data
    })
    // 通过postMessage给小程序传接口返回的数据
    setTimeout(() => {
      // 存在path并且path不为空 小程序方法跳转
      if (queryParams.path && queryParams.path != '') {
        if (queryParams.navigateName && ['navigateTo', 'switchTab', 'redirectTo', 'reLaunch'].includes(queryParams.navigateName)) {
          window.wx.miniProgram[queryParams.navigateName]({
            url: queryParams.path
          })
        } else {
          window.wx.miniProgram.navigateTo({
            url: queryParams.path
          })
        }
      }
      // path为空 back 返回首页
      else {
        window.wx.miniProgram.navigateBack()
      }
    }, 200)
  } else {
    const env = get("env");
    switch (env) {
      case "WEB":
        redirectSelfPage(data.instance_uri);
        yield put(setAgreeLoading(false));
        break;

      case "MOBILE":
        mobileCallback("loginCallBack", data);
        yield put(setLoginLoading(false));
        yield put(setAgreeLoading(false));
        break;

      default:
        console.error("Invalid env.");
    }
  }
}

/**
 * 标准登录-拒绝
 */
export function* disagreePrmProtocol() {
  const queryParams = queryString.parse(window.location.search);
  // 判断是不是在小程序环境中运行
  if (queryParams && queryParams.login_type === 'miniapp') {
    window.wx.miniProgram.postMessage({
      data: {
        action: 'privacyReject'
      }
    })
    window.wx.miniProgram.navigateBack()
  } else {
    const env = get("env");
    switch (env) {
      case "WEB":
        redirectSelfPage(window.location.origin + '/auc/logout');
        break;

      case "MOBILE":
        if (queryParams && compareVersion(JSON.parse(queryParams?.mobile_params)?._vs, '2218.24.1101') == -1) {
          yield put(setFatalError(intl.formatMessage(messages.historyTips)));
          yield put(setLoginLoading(false));
        } else {
          mobileCallback("privacyReject", {});
          yield put(setLoginLoading(false));
        }
        break;

      default:
        console.error("Invalid env.");
    }
  }
}

/**
 * 单点登录-拒绝
 */
export function* disagreePrmProtocolSingle({ device, mobileParams }) {
  if (device === 'web') {
    redirectSelfPage(window.location.origin + '/auc/logout');
  } else if (device === 'mobile') {
    let params = JSON.parse(mobileParams)
    // app
    if (params?._vs) {
      if (compareVersion(params?._vs, '2218.24.1101') == -1) {
        yield put(setTenantError(intl.formatMessage(messages.historyTips)));
        yield put(setLoginLoading(false));
      } else {
        mobileCallback("privacyReject", {});
        yield put(setLoginLoading(false));
      }
    }
    // 小程序
    else {
      window.wx.miniProgram.postMessage({
        data: {
          action: 'privacyReject'
        }
      })
      window.wx.miniProgram.navigateBack()
    }
  }
}

/**
 * 返回 0：表示两个版本 v1 和 v2 是相同的。
 * 返回 1：表示版本 v1 比 v2 新。
 * 返回 -1：表示版本 v1 比 v2 旧。
 */
export function compareVersion(v1, v2) {
  if (v1 == v2) {
    return 0
  }
  try {
    const vs1 = v1.split('.').map((a) => parseInt(a))
    const vs2 = v2.split('.').map((a) => parseInt(a))

    const length = Math.min(vs1.length, vs2.length)
    for (let i = 0; i < length; i++) {
      if (vs1[i] > vs2[i]) {
        return 1
      } else if (vs1[i] < vs2[i]) {
        return -1
      }
    }

    if (length == vs1.length) {
      return -1
    } else {
      return 1
    }
  } catch (error) {
    console.error(error)
    return -1
  }
}

/**
 * 小程序快速登录，同意、拒绝
 */
export function* wxLogin(ticket) {
  // 同意
  if (ticket) {
    window.wx.miniProgram.postMessage({
      data: {
        action: 'privacyAgree',
        ticket: ticket
      }
    })
  }
  // 拒绝
  else {
    window.wx.miniProgram.postMessage({
      data: {
        action: 'privacyReject'
      }
    })
  }
  window.wx.miniProgram.navigateBack()
}