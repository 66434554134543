import Logo from '#components/Logo';
import { jumpToLogin } from '#models/login/action';
import { defineMessages } from '#utils/i18n/intl';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import styled from 'styled-components';

const messages = defineMessages({
  hasAccount: {
    id: 'Header.hasAccount',
    defaultMessage: '已有账户?直接',
  },
  signIn: {
    id: 'Header.signIn',
    defaultMessage: '登 录',
  },
});

export const Wrapper = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
`;

export const ButtonWrapper = styled.div`
  line-height: 32px;
`;

export const Tip = styled.span`
  padding: 0 12px;
  font-size: 12px;
  color: #aaaaaa;
`;

export const Button = styled.button`
  background: #FFFFFF;
  border: 1px solid #0564f5;
  border-radius: 4px;
  font-size: 12px;
  color: #0564f5;
  min-width: 80px;
  height: 32px;
  vertical-align: 2px;
  cursor: pointer;
  @media (max-width: 370px) {
    width: 21.3vw;
    height: 8.53vw;
  }
`;

const Header = ({
  onSignIn,
}) => (
  <Wrapper>
    <LogoWrapper>
      <Logo isVertical />
    </LogoWrapper>
    <ButtonWrapper>
      <Tip>{intl.formatMessage(messages.hasAccount)}</Tip>
      <Button type="button" onClick={onSignIn}>{intl.formatMessage(messages.signIn)}</Button>
    </ButtonWrapper>
  </Wrapper>
);

Header.propTypes = {
  onSignIn: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    onSignIn() {
      dispatch(jumpToLogin());
    },
  };
}

export default connect(null, mapDispatchToProps)(Header);
